<template>
    <Header v-if="!pageHidden"></Header>
    <router-view></router-view>
    <Footer v-if="!pageHidden"></Footer>
</template>
<script>
    import Header from "@/components/commons/Header";
    import Footer from "@/components/commons/Footer";

    export default {
        name: "App",
        components: {Footer, Header},
        data() {
            return {
                pageHidden: false,
            };
        },
        methods: {},
        watch: {
            $route(to) {
                console.log(to);
                if (to.name === "Sitemap")
                  this.pageHidden = true;
                else
                  this.pageHidden = false;

                if(to.name === "ProductDetail")
                {
                  window.scrollTo(0, 0);
                }
            },
        },
    };
</script>

<style lang="scss"></style>
