import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFontAwesome,
  faEnvira,
  faMedium,
  faSkyatlas,
  faWhatsapp,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneAlt,
  faMailBulk,
  faMobileAlt,
  faEnvelopeOpen,
  faUserClock,
  faIndustry,
  faCogs,
  faSubway,
  faCarAlt,
  faAtom,
  faThumbsUp,
  faCheckCircle,
    faArrowRight
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFontAwesome,
  faEnvira,
  faMedium,
  faPhoneAlt,
  faMailBulk,
  faMobileAlt,
  faEnvelopeOpen,
  faUserClock,
  faIndustry,
  faCogs,
  faSubway,
  faCarAlt,
  faAtom,
  faSkyatlas,
  faThumbsUp,
  faCheckCircle,
  faWhatsapp,
  faLinkedin,
  faYoutube,
    faArrowRight
);

export default library;
