export default [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/products/:type?",
    name: "Products",
    component: () => import("../views/Products.vue"),
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: () => import("../views/Certificates.vue"),
  },

  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/products/detail/:slug",
    name: "ProductDetail",
    component: () => import("../views/ProductDetail"),
  },

  {
    path: "/sitemap",
    name: "Sitemap",
    component: () => import("../views/Sitemap"),
  },
];
