import { createStore } from "vuex";
import products from "../assets/products.json";

export default createStore({
  state: {
    products: products,
  },
  mutations: {},
  actions: {},
  modules: {},
});
