<template>
  <footer>
    <section id="footer1">
      <div class="container">
        <div class="row">
          <div class="col-md-4 text-center">
            <font-awesome-icon icon="phone-alt" size="3x" />
            <h4 class="color-theme mt-4">Have a question? Call Us</h4>
            <p class="mt-4">
              <a href="tel:+919748740792">Office 1 : +91-9748740792</a>
            </p>
          </div>

          <div class="col-md-4 text-center">
            <font-awesome-icon icon="mail-bulk" size="3x" />
            <h4 class="color-theme mt-4">Need Support?</h4>
            <p class="mt-4">
              <a href="mailto:sales@shreebalajipolytech.net"
                >sales@shreebalajipolytech.net</a
              >
            </p>
            <p>
              <a href="mailto:vishal@shreebalajipolytech.net"
                >vishal@shreebalajipolytech.net</a
              >
            </p>
          </div>

          <div class="col-md-4 text-center">
            <font-awesome-icon icon="user-clock" size="3x" />
            <h4 class="color-theme mt-4">We are open</h4>
            <p class="mt-4">Mon - Sat 10am - 7pm</p>
            <p>Sun - Closed</p>
          </div>
        </div>
      </div>
    </section>

    <section id="footer2">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="d-flex">
              <img src="../../assets/images/logo/logo_inverse.png" height="100" />
            </div>

            <p class="mt-3 ps-4">
              ISO 9001:2015 manufacturers of rubber solutions (gaskets, sealing
              rings, sheets etc) for water, sewage and drainage infrastructure
              and DI Pipe networks
            </p>

            <hr class="mt-4 mb-4" />

            <div class="d-flex mt-3">
              <font-awesome-icon icon="mobile-alt" />
              <label class="ms-3">
                &nbsp;
                <a href="tel:+919748740792">+91-9748740792</a>
              </label>
            </div>

            <div class="d-flex mt-3">
              <font-awesome-icon icon="envelope-open" />
              <label class="ms-3">
                <a href="mailto:sales@shreebalajipolytech.net"
                  >sales@shreebalajipolytech.net</a
                >
              </label>
            </div>
          </div>

          <div class="col-md-4 offset-md-1">
            <h4>Our Product Range</h4>
            <div class="underline_highlight"></div>
            <ul class="mt-4">
              <li> <router-link :to="{name: 'ProductDetail' , params : {slug : 'tyton-gaskets'}}">Tyton Gaskets</router-link></li>
              <li><router-link :to="{name: 'ProductDetail' , params : {slug : 'mj-gaskets'}}">MJ Gaskets</router-link></li>
              <li><router-link :to="{name: 'ProductDetail' , params : {slug : 'flange-gaskets-3mm'}}">Flange Gaskets</router-link></li>
              <li><router-link :to="{name: 'ProductDetail' , params : {slug : 'manhole-cover-gaskets'}}">Manhole Cover Gaskets</router-link></li>
              <li><router-link :to="{name: 'Products' , params : {type : 'extruded-rubber-profiles'}}">Extruded Rubber Profiles</router-link></li>
              <li><router-link :to="{name: 'Products' , params : {type : 'rubber-sheets'}}">Rubber Sheets</router-link></li>
              <li>Infrastructure Rubber Products</li>
            </ul>
          </div>

          <div class="col-md-3 text-center">
            <h4>Works Office</h4>
            <label class="mt-3">
              7 Haran Mukherjee Road <br />
              Belur Math <br />
              Howrah - 711202 , WB , India
            </label>

            <h4 class="mt-5">Regd Office</h4>
            <label class="mt-3">
              23A Netaji Subhas Road <br />
              1st Floor, Room No-27 <br />
              Kolkata - 700001 , WB , India
            </label>
          </div>
        </div>
      </div>
    </section>
    <section id="footer3">
      <div class="container content">
        <div>
          Copyright &copy; {{ year }}
          <strong>Shree Balaji Polytech Pvt Ltd.</strong> All rights reserved.
        </div>

        <div>
          <a
            class="me-4"
            href="https://api.whatsapp.com/send?phone=919748740732"
            target="_blank"
            title="Shree Balaji Whatsapp Contact"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" size="2x" />
          </a>
          <a
            class="me-4"
            href="https://in.linkedin.com/company/shree-balaji-polytech-products-pvt-ltd---india"
            target="_blank"
            title="Shree Balaji LinkedIn"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
          </a>

          <a
            href="  https://www.youtube.com/c/McWaneDuctilePipe"
            target="_blank"
            title="Shree Balaji Youtube"
          >
            <font-awesome-icon :icon="['fab', 'youtube']" size="2x" />
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: black;
}

#footer1 {
  background: #020d26;
  padding: 4rem 0;
  color: white;
}

#footer1 .row [class*="col-md"]:not(:last-child) {
  border-right: 2px solid #fff;

  @media (max-width: 768px) {
    border-right: none;
    border-bottom: 2px solid #fff;
    padding-bottom: 1rem;
  }
}

#footer1 p {
  font-weight: 300;

  a {
    color: white !important;
    transition: all ease 200ms;
    padding: 0.5rem;

    &:hover {
      color: $theme-color_light !important;
    }
  }
}

#footer2 {
  padding: 4rem 0;
  @media (max-width: 768px) {
    padding: 0;
  }
}

#footer2 p {
  font-size: 0.9rem;
  font-weight: 300;
  text-align: left;
  line-height: 1.5rem;
  color: #333;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  display: inline-block;
  width: calc(50% - 2px);
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  margin: 0.5rem 0;
  border-left: 1px solid #0004;
  border-radius: 2px;
  transition: all ease-in-out 200ms;
}

ul li:hover {
  color: $theme-color;
  cursor: pointer;
  border-left: 3px solid $theme-color;
  font-weight: 400;
}

#footer3 {
  font-size: 0.9rem;
  background: #020d26;
  color: white;
  padding: 1.5rem;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      transition: all ease 200ms;

      &:hover {
        color: $theme-color;
      }
    }

    @media (max-width: 768px) {
      display: inline-block;
      text-align: center;

      div {
        padding: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}

#footer3 a {
  color: white;
}
</style>
