<template>
    <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <img :src="images.logo" alt="ShreeBalaji"/>
            </router-link>
            <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    @click="navClicked()"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="showNavbar?'show':''" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link
                                class="nav-link"
                                aria-current="page"
                                :to="{ name: 'Home' }"
                                @click="navClicked()"
                        >
                            Home
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'About' }"
                                     @click="navClicked()"
                        >About
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Products' }"
                                     @click="navClicked()"
                        >Products
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Certificates' }"
                                     @click="navClicked()"
                        >Certificates
                        </router-link>
                    </li>

                    <!--          <li class="nav-item">-->
                    <!--            <router-link class="nav-link" :to="{ name: 'Contact' }"-->
                    <!--              >Contact Us-->
                    <!--            </router-link>-->
                    <!--          </li>-->
                    <li class="nav-item">
                        <a
                                class="nav-link"
                                href="https://api.whatsapp.com/send?phone=919748740792"
                                target="_blank"
                                @click="navClicked()"
                        >
                            Whatsapp Us</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {
                showNavbar: false,
                images: {
                    logo: require("@/assets/images/logo/logo.png"),
                },
            };
        },
        methods: {
            navClicked() {
                this.showNavbar = !this.showNavbar;
            }
        }
    };
</script>

<style scoped lang="scss">
    .navbar {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        z-index: 100;
    }

    .navbar-brand {
        font-weight: 500;
        color: white;
    }

    .navbar-brand img {
        height: 3rem;
    }

    .nav-item {
        margin: 0 0.5rem;
        transition: all ease 0.3s;
    }

    .nav-item:after {
        display: block;
        content: "";
        border-bottom: solid 3px orange;
        transform: scaleX(0);
        transition: transform 500ms ease-in-out;
        transform-origin: 100% 50%;
    }

    .nav-item:hover:after {
        transform: scaleX(1);
        transform-origin: 0 50%;
    }

    .nav-item .nav-link {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.09rem;
        font-size: 0.9rem;
        cursor: pointer;
        color: white;
    }

    @media (min-width: 768px) {
        .navbar {
            padding: 1rem 2rem;
        }
    }

    @media (max-width: 768px) {
        .navbar {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            background: #0007;
        }
        .navbar-collapse {
            background: $deep-blue-color;
        }
    }
</style>
